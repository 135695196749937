import { Breadcrumb, Row } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { memo } from 'react';
import ModalAddType from 'src/components/ModalAddType';
import { IBaseProps } from 'src/types';
import { SpinnerLoader } from './spinner';

interface IProps extends IBaseProps {
  renderRight?: JSX.Element | React.ReactNode;
  title?: string | JSX.Element | React.ReactNode;
  // formSearch?: JSX.Element | React.ReactNode;
  // paginate?: JSX.Element | React.ReactNode;
  breadcrumbs?: ItemType[];
  renderRightBreadcrumb?: JSX.Element | React.ReactNode;
  loading?: boolean;
}

function Container(props: IProps) {
  const { innerHeight: height } = window;
  return (
    <div style={{ position: 'relative', minHeight: height - 96, margin: 16, paddingBottom: 12 }}>
      {props.loading && <SpinnerLoader bg />}
      <Row align="middle" justify="space-between">
        {props.breadcrumbs && <Breadcrumb items={props.breadcrumbs} style={{ marginBottom: 8 }} />}
        {props.renderRightBreadcrumb}
      </Row>
      <Row justify="space-between" style={{ marginBottom: 4 }} align="middle">
        <h3>{props.title}</h3>
        <div>{props.renderRight}</div>
      </Row>
      {props.children}
      <ModalAddType />
    </div>
  );
}

export default memo(Container);
