import { Avatar, Dropdown, Layout, Menu, Space } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { memo, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutColor } from 'src/types';
import * as timeago from 'timeago.js';
import vi from 'timeago.js/lib/lang/vi';
import Chat from './components/Chat';

// Extend Day.js with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

timeago.register('vi', vi);

const { Header, Content, Sider } = Layout;

interface IProps {
  logo: string;
  menus: ItemType<MenuItemType>[];
  accountItems: ItemType[];
  accountAvatar: string;
  theme: LayoutColor;
  pathname: string;
  logoImage: string;
}

// interface IState {
//   editor: string;
//   open: boolean;
//   dataMessage: IMessages[];
//   total: number;
//   dataConversation: IConversation[];
//   openChat: boolean;
//   message: IMessages[];
//   infoConversation: any;
//   chatConversation: IConversation[];
//   nameUserConversation: any;
//   hasMore: boolean;
//   page: number;
//   isFocused: boolean;
//   key_search: string;
// }

// const initState: IState = {
//   editor: '',
//   open: false,
//   openChat: false,
//   message: [],
//   nameUserConversation: undefined,
//   infoConversation: undefined,
//   chatConversation: [],
//   dataMessage: [],
//   hasMore: false,
//   page: 1,
//   total: 0,
//   isFocused: false,
//   key_search: '',
//   dataConversation: [],
// };

// const svMessage = new ItemService<IMessages>(ECollection.message);
// const svConversation = new ItemService<IConversation>(ECollection.conversation);
// const svDirectusUsers = new ItemService<IConversationDirectusUsers>(ECollection.conversation_directus_users);

function VerticalLayout(props: IProps) {
  const [collapsed, setCollapsed] = useState(false);
  // const [dataCreate, setDataCreate] = useState<IMessages[]>(props.dataMessageCreate);
  const { innerHeight: height } = window;
  // const [state, setState] = useState<IState>(initState);
  // const { user } = useUser();

  const rootPath = useMemo<string>(() => {
    return `/${props.pathname.split('/')[1]}`;
  }, [props.pathname]);

  // const handleOpenChange = (newOpen: boolean) => {
  //   setState((prev) => ({ ...prev, open: newOpen }));
  // };

  // const fetchData = async (search?: string) => {
  //   setState((prev) => ({ ...prev, loading: true }));
  //   try {
  //     const query: IParams<IMessages> = {
  //       limit: 10,
  //       page: 1,
  //       sort: ['-date_created'],
  //       fields: ['*,file.*'],
  //       search,
  //     };
  //     const res = await svMessage.list(query);
  //     const total = await svMessage.getTotal(query);
  //     const checkIdUser = res.filter((item) => item.user_created !== user?.id);
  //     if (checkIdUser) {
  //       await onRead(checkIdUser?.map((item) => item.id));
  //       await onSent(checkIdUser?.map((item) => item.id));
  //     }

  //     setState((prev) => ({ ...prev, dataMessage: res.reverse(), total }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setState((prev) => ({ ...prev, loading: false }));
  // };

  // const fetchMoreData = async () => {
  //   setState((prev) => ({ ...prev, loading: true }));
  //   try {
  //     const query = {
  //       limit: 10,
  //       page: state.page + 1,
  //       sort: ['-date_created'],
  //       fields: ['*,file.*'],
  //       search: state.infoConversation.id,
  //     };
  //     const res = await svMessage.list(query);
  //     const total = await svMessage.getTotal(query);
  //     const checkIdUser = res.filter((item) => item.user_created !== user?.id);
  //     if (checkIdUser) {
  //       await onRead(checkIdUser?.map((item) => item.id));
  //       await onSent(checkIdUser?.map((item) => item.id));
  //     }
  //     if (res.length === 0) {
  //       setState((prev) => ({ ...prev, hasMore: false, total }));
  //     } else {
  //       setState((prev) => ({
  //         ...prev,
  //         dataMessage: [...res.reverse(), ...prev.dataMessage],
  //         page: prev.page + 1,
  //       }));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setState((prev) => ({ ...prev, loading: false }));
  // };

  // const onRead = async (ids: string[]) => {
  //   try {
  //     const data = {
  //       date_read: dayjs().tz('Asia/Ho_Chi_Minh').format(),
  //     };

  //     await svMessage.updateMulti(ids, data);
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };

  // const onSent = async (ids: string[]) => {
  //   try {
  //     const data = {
  //       date_received: dayjs().tz('Asia/Ho_Chi_Minh').format(),
  //     };

  //     await svMessage.updateMulti(ids, data);
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };

  // const handleOpenChat = async (messages: IMessages[], conversation: IConversation, user: any) => {
  //   await fetchData(conversation.id);
  //   const isExists = state.chatConversation.some((item) => item.id === conversation.id);
  //   setState((prevState) => ({
  //     ...prevState,
  //     message: messages,
  //     infoConversation: conversation,
  //     openChat: true,
  //     open: false,
  //     nameUserConversation: user,
  //   }));
  //   setDataCreate([]);

  //   if (!isExists) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       message: messages,
  //       infoConversation: conversation,
  //       // chatConversation: [...prevState.chatConversation.filter((item) => item.id !== conversation.id), conversation],
  //       openChat: true,
  //       open: false,
  //       nameUserConversation: user,
  //     }));
  //     setDataCreate([]);
  //   }
  // };

  // const fetchDataConverSation = async () => {
  //   setState((prev) => ({ ...prev, loading: true }));
  //   try {
  //     const query: IParams<IConversation> = {
  //       limit: 20,
  //       sort: ['-date_last'],
  //       fields: [
  //         '*,user_created.*, messages.id,messages.content,messages.file.id, messages.date_created,messages.date_read,messages.user_created.id,user_id.id,user_id.first_name,user_id.last_name,user_id.avatar,users.directus_users_id.id,users.directus_users_id.avatar,users.directus_users_id.first_name,users.directus_users_id.last_name,*',
  //       ],
  //       deep: {
  //         messages: {
  //           _sort: '-date_created',
  //           _limit: '1',
  //         },
  //       },
  //     };
  //     const res = await svConversation.list(query);
  //     const total = await svConversation.getTotal(query);
  //     setState((prev) => ({ ...prev, dataConversation: res, total }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setState((prev) => ({ ...prev, loading: false }));
  // };

  // const handleOpenChatUser = async (item: IUser) => {
  //   try {
  //     const query = {
  //       fields: ['*,user_created.id'],
  //       'filter[_and][0][directus_users_id][id][_eq]': item?.id.toString(),
  //       'filter[_and][1][conversation_id][users][directus_users_id][id][_eq]': user?.id,
  //     };
  //     const res = await svDirectusUsers.list(query);
  //     if (res.length > 0) {
  //       fetchData(res[0].conversation_id);
  //       const isExists = state.chatConversation.some((item) => item.id === res[0].conversation_id);
  //       setState((prevState) => ({
  //         ...prevState,
  //         openChat: true,
  //         open: false,
  //         nameUserConversation: item,
  //       }));
  //       setDataCreate([]);
  //       if (!isExists) {
  //         setState((prevState) => ({
  //           ...prevState,
  //           openChat: true,
  //           open: false,
  //           nameUserConversation: item,
  //         }));
  //         setDataCreate([]);
  //       }
  //     } else {
  //       const ids = [item.id, user?.id];
  //       const body: any = {
  //         name: `${item.first_name}${user?.first_name}`,
  //         users: {
  //           create: ids.map((id) => ({
  //             directus_users_id: id,
  //             conversation_id: '+',
  //             role: 1,
  //           })),
  //         },
  //       };
  //       const res = await svConversation.create(body);
  //       fetchData(res.id);
  //       if (res) {
  //         setState((prevState) => ({
  //           ...prevState,
  //           openChat: true,
  //           infoConversation: res,
  //           open: false,
  //           nameUserConversation: item,
  //         }));
  //         setDataCreate([]);
  //       }
  //     }
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };

  // const toggleChatWindow = (id: string) => {
  //   setState((prev) => ({
  //     ...prev,
  //     chatConversation: state.chatConversation.filter((item) => item.id !== id),
  //     openChat: false,
  //   }));
  // };

  // const hidenChatWindow = () => {
  //   setState((prev) => ({
  //     ...prev,
  //     openChat: false,
  //   }));
  // };

  // const onDeleteConversation = (id: string) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     openChat: false,
  //     chatConversation: state.chatConversation.filter((item) => item.id !== id),
  //   }));
  // };

  // const handleFocus = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     isFocused: true,
  //   }));
  // };

  // const handleBlur = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     isFocused: false,
  //   }));
  // };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: props.theme === LayoutColor.DARK ? '#002140' : 'white',
          borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
        }}
      >
        <div
          style={{
            display: 'flex',
            margin: '16px 6px',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <img src={props.logoImage} alt="" width="40" />
          <h3 style={{ color: props.theme === LayoutColor.DARK ? 'white' : 'black', marginLeft: 8 }}>{props.logo}</h3>
        </div>

        <Space>
          <Chat theme={props.theme} />

          <Dropdown menu={{ items: props.accountItems }} placement="bottomLeft">
            <Avatar size="large" src={<img src={props.accountAvatar} alt="avatar" />} style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Space>
      </Header>

      <Layout>
        <Sider
          theme={props.theme}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ height: height - 104, overflow: 'scroll' }}
        >
          <Menu
            theme={props.theme}
            mode="inline"
            // defaultOpenKeys={[pathBack]}
            selectedKeys={[rootPath]}
            items={props.menus}
          />
        </Sider>
        <Content
          style={{
            position: 'relative',
            maxHeight: height - 64,
            overflowY: 'scroll',
          }}
        >
          <Outlet />
        </Content>
        {/* <div className="floating-button-container">
          {state.chatConversation.map((item) => {
            const user = item.users?.find((e) => e.directus_users_id.id !== item.user_created.id)?.directus_users_id;
            return (
              <div className="custom-button-wrapper" key={item.id}>
                <Button className="floating-button" onClick={() => handleOpenChat(item.messages, item, user)}>
                  <Avatar src={FUNCS.getFullMedialUrl(user?.avatar)} className="avatar-in-button" />
                </Button>
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  className="custom-close-button"
                  size="small"
                  onClick={() => onDeleteConversation(item.id)}
                />
              </div>
            );
          })}

          {state.openChat && (
            <ChatWindow
              onClose={() => toggleChatWindow(state.infoConversation.id)}
              hidenChatWindow={hidenChatWindow}
              onLoadMore={fetchMoreData}
              hasMore={state.hasMore}
              messageTotal={state.total}
              messagePage={state.page}
              open={state.openChat}
              chat={state.dataMessage}
              info={state.infoConversation}
              nameUser={state.nameUserConversation}
              dataMessageSocket={props.dataMessageSocket}
              dataMessageCreate={dataCreate}
            />
          )}
        </div> */}
      </Layout>
    </Layout>
  );
}

export default memo(VerticalLayout);
