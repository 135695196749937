import { PlusOutlined } from '@ant-design/icons';
import { Col, FormInstance, Row, Space, Tooltip } from 'antd';
import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { ECollection, ItemService } from 'src/services';
import { useTenant } from 'src/stores';
import {
  EPropertyCategorySelectType,
  EPropertyCategoryType,
  ICreatePropertyBody,
  IProperty,
  IPropertyCategory,
  IUpdatePropertyBody,
} from 'src/types';
import { CONTANTS, MESSAGE } from 'src/utils';
import IconFont from '../IconFont';
import PropertyAdd from './PropertyAdd';
import PropertyRow from './PropertyRow';

interface IProps {
  propertyCategory: IPropertyCategory;
  form: FormInstance<any>;
  span: number;
  required: boolean;
  onChangeProperty: (values: IPropertyValue[]) => void;
  initValues?: IPropertyValue[];
}

export interface IPropertyValue {
  label: string;
  value: string;
  id?: string;
  image?: string;
  icon?: string;
  originId?: number;
}

const svProperty = new ItemService<IProperty, ICreatePropertyBody, IUpdatePropertyBody>(ECollection.property);

function PropertyInput({ form, propertyCategory, span, required, onChangeProperty, initValues }: IProps) {
  const [values, setValues] = useState<IPropertyValue[]>(initValues || []);
  const [showAdd, setShowAdd] = useState<boolean>(!(initValues && initValues.length > 0));
  const [propertyOpts, setPropertyOpts] = useState<IPropertyValue[]>(
    propertyCategory?.properties.map((i) => ({
      label: i.label,
      value: i.value,
      id: i.id,
      image: i.image,
      icon: i.icon,
    })),
  );
  const { colorLighter } = useTenant();

  useEffect(() => {
    onChangeProperty(values);
  }, [values]);

  const onClose = (val: string) => {
    setValues((prev) => prev.filter((i) => i.value !== val));
  };

  const onSubmit = (data: { image?: string; icon?: string }) => {
    if (propertyCategory.select_type === EPropertyCategorySelectType.COMBO) {
      const val = form.getFieldValue(propertyCategory.id);
      const property = propertyOpts.find((i) => i.value === val);
      if (!property) return;
      if (values.findIndex((i) => i.value === val) > -1) {
        MESSAGE.error(lang.t('library.attribute_value_existed'));
        return;
      }

      setValues((prev) => [...prev, property]);
      setShowAdd(false);
      form.setFieldValue(propertyCategory.id, null);
    } else {
      const value = form.getFieldValue(`value.${propertyCategory.id}`);
      if (!value) {
        MESSAGE.error(lang.t('library.attribute_value_not_empty'));
        return;
      }
      let val = '';
      switch (propertyCategory.type) {
        case EPropertyCategoryType.TIME:
          val = value.format(CONTANTS.DATE_SERVER);
          break;
        case EPropertyCategoryType.COLOR:
          val = typeof value === 'string' ? value : value.toHexString();
          break;

        default:
          val = value;
          break;
      }

      const label = form.getFieldValue(`label.${propertyCategory.id}`);
      if (values.findIndex((i) => i.value === val) > -1) {
        MESSAGE.error(lang.t('library.attribute_value_existed'));
        return;
      }

      setValues((prev) => [...prev, { label, value: val, ...data }]);
      setShowAdd(false);
      form.setFieldsValue({
        [`value.${propertyCategory.id}`]: undefined,
        [`label.${propertyCategory.id}`]: undefined,
      });
    }
  };

  const onEdit = (val: string, data: IPropertyValue) => {
    if (val !== data.value && values.findIndex((i) => i.value === data.value) > -1) {
      MESSAGE.error(lang.t('library.attribute_value_existed'));
      return;
    }

    setValues((prev) =>
      prev.map((i) => {
        if (i.value === val) {
          return {
            ...i,
            ...data,
          };
        }
        return i;
      }),
    );
  };

  const onAddProperty = async (data: { image?: string; icon?: string }) => {
    let val = '';
    switch (propertyCategory.type) {
      case EPropertyCategoryType.TIME:
        val = form.getFieldValue(`value.${propertyCategory.id}`).format(CONTANTS.DATE_SERVER);
        break;
      case EPropertyCategoryType.COLOR:
        val =
          typeof form.getFieldValue(`value.${propertyCategory.id}`) === 'string'
            ? form.getFieldValue(`value.${propertyCategory.id}`)
            : form.getFieldValue(`value.${propertyCategory.id}`).toHexString();
        break;

      default:
        val = form.getFieldValue(`value.${propertyCategory.id}`);
        break;
    }

    const label = form.getFieldValue(`label.${propertyCategory.id}`);
    if (propertyOpts.findIndex((i) => i.value === val) > -1) {
      MESSAGE.error(lang.t('library.attribute_value_existed'));
      return;
    }

    try {
      const res = await svProperty.create({
        value: val,
        label,
        tenant_id: { id: propertyCategory.tenant_id },
        property_category_id: propertyCategory.id,
        icon: data.icon,
        image: data.image,
      });
      MESSAGE.success(lang.t('library.add_attribute_value_success'));

      setPropertyOpts((prev) => [...prev, { label, value: val, id: res.id, ...data }]);
      form.setFieldsValue({
        [`value.${propertyCategory.id}`]: undefined,
        [`label.${propertyCategory.id}`]: undefined,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteProperty = async (id: string) => {
    if (!id) return false;
    try {
      await svProperty.delete(id);
      MESSAGE.success(lang.t('library.delete_attribute_value_success'));
      setPropertyOpts((prev) => prev.filter((i) => i.id !== id));
      setValues((prev) => prev.filter((i) => i.id !== id));
      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  return (
    <Col span={span}>
      <div style={{ backgroundColor: colorLighter }} className="property-category">
        <Row justify="space-between" align="middle">
          <span>
            {required && <span style={{ color: 'red' }}>*</span>}{' '}
            {propertyCategory.icon && <IconFont type={`icon-${propertyCategory.icon}`} />}{' '}
            {`${propertyCategory.name} (${propertyCategory.max})`}
          </span>
          <Space className="property-category-btn-wrapper">
            {values.length < (propertyCategory.max || 0) && (
              <Tooltip title={lang.t('property_category.created_property')}>
                <PlusOutlined className="property-category-btn" onClick={() => setShowAdd(true)} />
              </Tooltip>
            )}
          </Space>
        </Row>

        <div style={{ marginLeft: 10, marginTop: 4 }}>
          {values.map((i) => {
            return (
              <PropertyRow
                propertyCategory={propertyCategory}
                property={i}
                key={i.value}
                onClose={() => onClose(i.value)}
                form={form}
                onSubmitEdit={(data) => onEdit(i.value, data)}
                opts={propertyOpts}
                onAddProperty={onAddProperty}
                onDelete={onDeleteProperty}
              />
            );
          })}

          {(showAdd || values.length === 0) && (
            <PropertyAdd
              propertyCategory={propertyCategory}
              propertyOpts={propertyOpts}
              onAddProperty={onAddProperty}
              onDeleteProperty={onDeleteProperty}
              form={form}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </Col>
  );
}

export default memo(PropertyInput);
