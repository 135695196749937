/* eslint-disable no-case-declarations */
import { WechatWorkOutlined } from '@ant-design/icons';
import { Badge, Popover } from 'antd';
import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { useSocket } from 'src/providers/socket.provider';
import { ECollection } from 'src/services';
import { useUser } from 'src/stores';
import { LayoutColor } from 'src/types';
import ConversationPopover from './ConversationPopover';

interface IProps {
  theme: LayoutColor;
}

function Chat(props: IProps) {
  const [unread, setUnread] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const { message, isAuth, onSubscribe, onUnSubscribe } = useSocket();
  const { user } = useUser();

  useEffect(() => {
    if (open) {
      setUnread(0);
    }
  }, [open]);

  useEffect(() => {
    onSubscribe &&
      isAuth &&
      onSubscribe(ECollection.message, 'new_message', {
        filter: {
          conversation_id: {
            users: {
              directus_users_id: { _eq: user?.id },
            },
          },
          user_created: { _neq: user?.id },
        },
      });

    return () => {
      onUnSubscribe && onUnSubscribe('new_message');
    };
  }, [isAuth]);

  useEffect(() => {
    if (message?.uid !== 'new_message') return;
    switch (message?.event) {
      case 'create':
        const countNewMessage = message.data.length;
        !open && setUnread((prev) => prev + countNewMessage);

        break;

      default:
        break;
    }
  }, [message]);

  return (
    <Popover
      placement="bottomRight"
      title={<b style={{ fontSize: '18px' }}>{lang.t('layout.conversation')}</b>}
      trigger="click"
      content={<ConversationPopover onClose={() => setOpen(false)} />}
      onOpenChange={(open) => {
        setOpen(open);
      }}
      arrow={false}
      // style={{ height, overflow: 'scroll' }}
      overlayClassName="custom-popover"
      open={open}
      destroyTooltipOnHide
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
        <Badge count={unread}>
          <WechatWorkOutlined style={{ fontSize: '39px', color: props.theme === LayoutColor.DARK ? '#1890ff' : '' }} />
        </Badge>
      </div>
    </Popover>
  );
}

export default memo(Chat);
