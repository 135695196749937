import { Form } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { BaseFilterDrawler, BaseTreeSelect } from 'src/libraries';
import { ECollection, ItemService } from 'src/services';
import { useTenant } from 'src/stores';
import { IDepartment, ISetting } from 'src/types';
import { FUNCS } from 'src/utils';

interface IProps {
  onClose: () => void;
  open: boolean;
  setting: ISetting;
  onFilter: (values: any) => void;
}

const getSettingParent = (setting: ISetting, settings: ISetting[]) => {
  const parents: string[] = [];

  const getParent = (s: ISetting) => {
    s.parents.forEach((i) => {
      const settingParent = settings.find((e) => e.id === i.parent_id);

      if (settingParent) {
        parents.push(i.parent_id);
        getParent(settingParent);
      }
    });
  };

  getParent(setting);

  return parents;
};

const sv = new ItemService<IDepartment>(ECollection.department);

function FilterDrawler(props: IProps) {
  const [form] = Form.useForm();
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { tenant } = useTenant();

  useEffect(() => {
    if (tenant?.settings) {
      fetchDepartmentParent(getSettingParent(props.setting, tenant?.settings || []));
      form.resetFields();
    }
  }, [tenant, props.setting]);

  const fetchDepartmentParent = async (department_parent_ids: string[]) => {
    if (department_parent_ids.length === 0) {
      setTreeData([]);
      return;
    }

    if (loading) return;
    setLoading(true);
    try {
      const res = await sv.list({ fields: ['*', 'parents.*'], filter: { setting_id: { _in: department_parent_ids } } });
      setTreeData(FUNCS.getTreeDataMTM<IDepartment>(res));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFilter = () => {
    props.onFilter(form.getFieldsValue());
  };

  return (
    <BaseFilterDrawler onClose={props.onClose} onFilter={onFilter} open={props.open}>
      <Form form={form} layout="vertical">
        <BaseTreeSelect
          name="department_parent_ids"
          showCheckedStrategy="SHOW_CHILD"
          span={24}
          label={lang.t('department.parent_ids')}
          treeData={treeData}
        />
      </Form>
    </BaseFilterDrawler>
  );
}

export default memo(FilterDrawler);
