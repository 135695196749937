import { CheckOutlined, DeleteOutlined, EditOutlined, FileImageOutlined } from '@ant-design/icons';
import { FormInstance, Image, Row, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { memo, useRef, useState } from 'react';
import lang from 'src/lang/lang';
import { FileService } from 'src/services';
import { useTenant } from 'src/stores';
import { EPropertyCategorySelectType, EPropertyCategoryType, IPropertyCategory } from 'src/types';
import { CONTANTS, FUNCS, MESSAGE } from 'src/utils';
import { IPropertyValue } from './PropertyInput';
import PropertySelect from './PropertySelect';
import { renderContent } from './renderContent';
import { renderInput } from './renderInput';

interface IProps {
  propertyCategory: IPropertyCategory;
  form: FormInstance<any>;
  property: IPropertyValue;
  onClose: () => void;
  onSubmitEdit: (data: IPropertyValue) => void;
  opts: IPropertyValue[];
  onAddProperty: (data: { image?: string; icon?: string }) => void;
  onDelete: (id: string) => void;
}

const svFile = new FileService();

function PropertyRow({ form, propertyCategory, property, onClose, onSubmitEdit, ...props }: IProps) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { colorLight, tenant } = useTenant();
  const inputFile = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<{ image?: string; icon?: string }>({ image: property.image, icon: property.icon });

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;

    const file = e.target.files[0];
    try {
      const res = await svFile.upload(file, {});
      setState((prev) => ({ ...prev, image: res.id }));
    } catch (error) {
      console.log(error);
    }
  };

  const onEdit = () => {
    if (propertyCategory.select_type === EPropertyCategorySelectType.COMBO) {
      form.setFieldsValue({
        [`${property.value || ''}.${propertyCategory.id}`]: property.value,
      });
    } else {
      let val: any;
      switch (propertyCategory.type) {
        case EPropertyCategoryType.TIME:
          val = dayjs(property.value);
          break;
        case EPropertyCategoryType.COLOR:
          val = property.value;
          break;

        default:
          val = property.value;
          break;
      }
      form.setFieldsValue({
        [`value.${property.value || ''}.${propertyCategory.id}`]: val,
        [`label.${property.value || ''}.${propertyCategory.id}`]: property.label,
      });
    }

    setEditMode(true);
  };

  const onSubmit = () => {
    if (propertyCategory.select_type === EPropertyCategorySelectType.COMBO) {
      const val = form.getFieldValue(`${property.value}.${propertyCategory.id}`);
      const propertySlt = props.opts.find((i) => i.value === val);
      if (!propertySlt) return;
      onSubmitEdit(propertySlt);
      form.setFieldValue(`${property.value}.${propertyCategory.id}`, null);
    } else {
      let val = '';
      const nameValue = `value.${property.value}.${propertyCategory.id}`;
      const value = form.getFieldValue(nameValue);
      if (!value) {
        MESSAGE.error(lang.t('library.attribute_value_not_empty'));
        return;
      }
      switch (propertyCategory.type) {
        case EPropertyCategoryType.TIME:
          val = value.format(CONTANTS.DATE_SERVER);
          break;
        case EPropertyCategoryType.COLOR:
          val = typeof value === 'string' ? value : value.toHexString();
          break;

        default:
          val = value;
          break;
      }

      const label = form.getFieldValue(`label.${property.value}.${propertyCategory.id}`);

      onSubmitEdit({ label, value: val, ...state });

      form.setFieldsValue({
        [nameValue]: undefined,
        [`label.${property.value}.${propertyCategory.id}`]: undefined,
      });
    }
    setEditMode(false);
  };

  if (editMode) {
    return (
      <Row className="property" align="middle" style={{ backgroundColor: colorLight }}>
        <div style={{ flex: 1 }}>
          {propertyCategory.select_type === EPropertyCategorySelectType.COMBO ? (
            <PropertySelect
              name={`${property.value || ''}.${propertyCategory.id}`}
              propertyCategory={propertyCategory}
              opts={props.opts}
              onAddProperty={props.onAddProperty}
              onDelete={props.onDelete}
              form={form}
            />
          ) : (
            <Row align="middle">
              <Tooltip title="Ảnh thuộc tính">
                {state.image ? (
                  <Image
                    src={FUNCS.getFullMedialUrl(state.image)}
                    width={24}
                    height={24}
                    preview={false}
                    style={{ cursor: 'pointer' }}
                    onClick={() => inputFile.current?.click()}
                  />
                ) : (
                  <FileImageOutlined
                    style={{ color: tenant?.color, cursor: 'pointer' }}
                    onClick={() => inputFile.current?.click()}
                  />
                )}
              </Tooltip>
              <input
                type="file"
                id="upload"
                onChange={(e) => onUpload(e)}
                className="hidden"
                accept="image/*"
                ref={inputFile}
              />
              <div style={{ flex: 1, marginLeft: 8 }}>{renderInput(propertyCategory, form, property.value)}</div>
            </Row>
          )}
        </div>
        <Space style={{ marginLeft: 20 }}>
          <Tooltip title={lang.t('default.save')}>
            <CheckOutlined className="property-btn" onClick={onSubmit} />
          </Tooltip>
          {/* <Tooltip title="Làm mới">
      <DeleteOutlined className="property-btn" />
    </Tooltip> */}
        </Space>
      </Row>
    );
  }

  return (
    <Row className="property" style={{ backgroundColor: colorLight }} align="middle">
      <div style={{ flex: 1 }}>
        <Row align="middle">
          {property.image && (
            <Tooltip title={lang.t('library.attribute_picture')}>
              <Image src={FUNCS.getFullMedialUrl(property.image)} width={24} height={24} />
            </Tooltip>
          )}

          <div style={{ flex: 1, marginLeft: 8 }}>{renderContent(propertyCategory, property)}</div>
        </Row>
      </div>
      <Space style={{ marginLeft: 20 }} className="property-btn-wrapper">
        <Tooltip title={lang.t('achievement.edit.edit')}>
          <EditOutlined className="property-btn" onClick={onEdit} />
        </Tooltip>
        <Tooltip title={lang.t('achievement.index.delete_text')}>
          <DeleteOutlined className="property-btn" onClick={onClose} />
        </Tooltip>
      </Space>
    </Row>
  );
}

export default memo(PropertyRow);
