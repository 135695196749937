import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ECollection, ItemService } from 'src/services';
import { useUser } from 'src/stores';
import { ICreateFieldViewBody, IField, IFieldBody, IFieldView, ISetting, IUpdateFieldViewBody } from 'src/types';

interface IProps {
  info: ISetting;
  cols: any[];
}

interface IState {
  userFields: IField[];
  editFields: IFieldBody[];
  fieldViewId?: string;
  loadedField?: boolean;
}

const initState: IState = {
  userFields: [],
  editFields: [],
};

const svFieldView = new ItemService<IFieldView, ICreateFieldViewBody, IUpdateFieldViewBody>(ECollection.field_view);

export function useTableUserFlex(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const { user } = useUser();

  useEffect(() => {
    fetchFieldView();
  }, []);

  useEffect(() => {
    if (state.loadedField) {
      upsertFieldView();
    }
  }, [state.editFields]);

  const fetchFieldView = async () => {
    try {
      const res = await svFieldView.list({
        fields: ['*'],
        filter: {
          setting_id: { _eq: props.info.id },
          user_id: { _eq: user?.id },
        },
      });
      if (res) {
        const fields = [...(res[0]?.fields || props.info.fields.filter((i) => i.show))].sort(
          (a, b) => a.priority - b.priority,
        );
        setState((prev) => ({
          ...prev,
          userFields: fields,
          editFields: fields,
          fieldViewId: res[0]?.id,
          loadedField: true,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const upsertFieldView = async () => {
    try {
      if (state.fieldViewId) {
        // cập nhật
        await svFieldView.update(state.fieldViewId, {
          fields: state.editFields.map((i, index) => ({ ...i, priority: index + 1 })),
        });
      } else {
        // Thêm mới
        await svFieldView.create({
          fields: state.editFields.map((i, index) => ({ ...i, priority: index + 1 })),
          user_id: user?.id || '',
          setting_id: props.info.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDragField = (fields: IFieldBody[]) => {
    setState((prev) => ({ ...prev, editFields: fields }));
  };

  const onResizeField = (field: string, width: number) => {
    setState((prev) => ({
      ...prev,
      editFields: prev.editFields.map((i) => {
        if (field === i.field) {
          return { ...i, width };
        }
        return i;
      }),
    }));
  };

  const onCheckFields = (fields: string[]) => {
    setState((prev) => {
      const fieldsPrev = prev.editFields.map((i) => i.field);

      const fieldAdd = _.difference(fields, fieldsPrev);
      const fieldRemove = _.difference(fieldsPrev, fields);
      if (fieldAdd.length > 0) {
        const field = prev.userFields.find((i) => i.field === fieldAdd[0]);

        if (field) {
          return {
            ...prev,
            editFields: [...prev.editFields, { field: field.field, width: field.width }],
          };
        }
        const fieldSetting = props.info.fields.find((i) => i.field === fieldAdd[0]);
        if (fieldSetting) {
          return {
            ...prev,
            editFields: [...prev.editFields, { field: fieldSetting.field, width: fieldSetting.width }],
            userFields: [
              ...prev.userFields,
              {
                field: fieldSetting.field,
                width: fieldSetting.width,
                label: fieldSetting.label,
                priority: prev.userFields.length + 1,
              },
            ],
          };
        }
      }

      if (fieldRemove.length > 0) {
        return {
          ...prev,
          editFields: prev.editFields.filter((i) => i.field !== fieldRemove[0]),
        };
      }

      return prev;
    });
  };

  const colsFlex = useMemo<any[]>(() => {
    const result: any[] = [];

    state.userFields.forEach((i) => {
      const col = props.cols.find((e) => e.key === i.field);
      const title = props.info.fields.find((e) => e.field === i.field)?.label;
      if (col) {
        result.push({ ...col, className: 'dragable', width: i.width, title });
      }
    });

    return result;
  }, [state.userFields]);

  const fieldsFilter = useMemo<string[]>(() => {
    const result: string[] = [];

    state.userFields.forEach((i) => {
      const col = props.cols.find((e) => e.key === i.field);
      if (col) {
        (col.fields || []).forEach((e: string) => {
          if (!result.includes(e)) result.push(e);
        });
      }
    });

    return result;
  }, [state.userFields]);

  return {
    userFields: state.userFields,
    loadedField: state.loadedField,
    editFields: state.editFields,
    onDragField,
    onResizeField,
    onCheckFields,
    colsFlex,
    fieldsFilter,
  };
}
