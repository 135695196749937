import { DeleteOutlined, DownloadOutlined, MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Popover, Space, Tooltip } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import lang from 'src/lang/lang';
import { BasePaginate, BaseTable, Container, FormSearch } from 'src/libraries';
import { ECollection, ItemService } from 'src/services';
import {
  EExportParam,
  IBaseState,
  IDepartment,
  IDepartmentQuery,
  IField,
  IFieldBody,
  IParams,
  ISetting,
} from 'src/types';
import { CONTANTS, EVENTS, FUNCS, MESSAGE } from 'src/utils';
import SettingPopover from '../../components/SettingPopover';
import { cols } from './columns';
import FilterDrawler from './components/FilterDrawler';
import { useTableUserFlex } from 'src/hooks';

interface IProps {
  info: ISetting;
}

interface IState extends IBaseState<IDepartment> {
  userFields: IField[];
  editFields: IFieldBody[];
  fieldViewId?: string;
  loadedField?: boolean;
}

const initState: IState = {
  loading: false,
  total: 0,
  data: [],
  refresh: false,
  detail: undefined,
  selected: [],
  page: 1,
  filterDrawler: false,
  countFilter: 0,
  userFields: [],
  editFields: [],
};

const sv = new ItemService<IDepartment>(ECollection.department);

function DepartmentScreen(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const [params, setParams] = useState<IDepartmentQuery>({
    limit: CONTANTS.PAGE_SIZE,
    page: 1,
  });
  const { pathname } = useLocation();
  const tableFlex = useTableUserFlex({ info: props.info, cols });

  useEffect(() => {
    tableFlex.loadedField && fetchData();
  }, [params, state.refresh, tableFlex.loadedField, tableFlex.userFields]);

  const fetchData = async (search?: string) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const query: IParams<IDepartment> = {
        limit: params.limit,
        page: params.page,
        filter: {
          setting_id: { _eq: props.info.id },
          parents: { _some: { parent_id: { _in: params.department_parent_ids } } },
        },
        fields: ['id', ...tableFlex.fieldsFilter],
        search,
      };
      const res = await sv.list(query);
      const total = await sv.getTotal({ filter: query.filter, search });
      setState((prev) => ({ ...prev, data: res, total }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const onPageChange = (page: number): void => {
    setParams({ ...params, page });
  };

  // thay đổi bản ghi
  const onRecordChange = (limit: number): void => {
    setParams({ ...params, page: 1, limit });
  };

  const debounceFn = _.debounce(fetchData, 800);

  const onSearch = (value: string) => {
    debounceFn(value);
  };

  const onClearSearch = () => {
    setState((prev) => ({ ...prev, countFilter: 0 }));
    setParams({
      page: params.page,
      limit: params.limit,
    });
  };

  const onFilter = (values: any) => {
    let count = 0;

    Object.keys(values).forEach((i) => {
      if (values[i]) {
        if (Array.isArray(values[i])) {
          if (values[i].length > 0) count += 1;
        } else {
          count += 1;
        }
      }
    });

    setParams((prev) => ({
      ...prev,
      department_parent_ids: values.department_parent_ids
        ? _.uniq((values.department_parent_ids as string[]).map((i) => i.split('.')[1]))
        : [],
    }));

    setState((prev) => ({ ...prev, countFilter: count, filterDrawler: false }));
  };

  const onConfirmDelete = (item: IDepartment) => {
    EVENTS.onShowModalConfirm({
      title: lang.t('default.delete_label', {
        label: props.info.label.toLocaleLowerCase(),
      }),
      content: lang.t('default.delete_confirm_success', {
        label: props.info.label.toLocaleLowerCase(),
      }),
      onOk: () => onDelete(item),
    });
  };

  const onDelete = async (item: IDepartment) => {
    if (!item) return false;
    try {
      await sv.delete(item.id);
      MESSAGE.success(
        lang.t('default.delete_success', {
          label: props.info.label.toLocaleLowerCase(),
        }),
      );
      setState((prev) => ({ ...prev, refresh: !prev.refresh }));
      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  const onConfirmDeleteMulti = () => {
    EVENTS.onShowModalConfirm({
      title: lang.t('default.delete_label', {
        label: props.info.label.toLocaleLowerCase(),
      }),
      content: lang.t('default.deletemuti_confirm_success', {
        label: props.info.label.toLocaleLowerCase(),
      }),
      onOk: () => onDeleteMulti(state.selected),
    });
  };

  const onDeleteMulti = async (items: IDepartment[]) => {
    if (!items.length) return false;
    try {
      await sv.deleteMulti(items.map((i) => i.id));
      MESSAGE.success(lang.t('default.delete_success', { label: props.info.label.toLocaleLowerCase() }));
      setState((prev) => ({ ...prev, refresh: !prev.refresh, selected: [] }));
      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  const onExport = () => {
    EVENTS.onShowExportDrawler({
      title: props.info.label.toLocaleLowerCase(),
      onOk: (values) => onExportDepartment(values),
    });
  };

  const onImport = () => {
    EVENTS.onShowImportDrawler({
      title: props.info.label.toLocaleLowerCase(),
      onOk: (values) => onImportDepartment(values),
    });
  };

  const onExportDepartment = async (values: { type: EExportParam; count?: number | undefined }) => {
    try {
      const query: IParams<IDepartment> = {
        limit: values.count,
        page: params.page,
        filter: {
          setting_id: { _eq: props.info.id },
          parents: { _some: { parent_id: { _in: params.department_parent_ids } } },
        },
        fields: ['id', 'name', 'date_created', 'setting_id'],
        export: values.type,
        // search,
      };
      const res = await sv.export(query);
      MESSAGE.success(lang.t('default.export_file_success'));
      if (res) {
        const data = values.type === EExportParam.json ? JSON.stringify(res) : res;
        FUNCS.download(
          data,
          lang.t('default.list_download', { label: props.info.label.toLocaleLowerCase(), type: values.type }),
          values.type,
        );
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onImportDepartment = async (file: File) => {
    try {
      await sv.import(file);
      MESSAGE.success(lang.t('default.import_file_success', { name: props.info.label.toLocaleLowerCase() }));
      setState((prev) => ({ ...prev, refresh: !prev.refresh }));
      return true;
    } catch (error) {
      console.log();
      return false;
    }
  };

  const columns = useMemo<any[]>(() => {
    return [
      {
        title: (
          <Popover
            placement="bottomLeft"
            content={
              <SettingPopover
                onCheck={tableFlex.onCheckFields}
                cols={tableFlex.userFields.map((i) => i.field)}
                setting={props.info}
              />
            }
            trigger="click"
          >
            <Tooltip title={lang.t('default.display_setting')} placement="top">
              <MoreOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Tooltip>
          </Popover>
        ),
        key: 'action',
        render(_: any, record: IDepartment) {
          const items: MenuProps['items'] = [
            {
              key: 'delete',
              label: lang.t('default.delete'),
              onClick: () => onConfirmDelete(record),
              icon: <DeleteOutlined />,
            },
          ];
          return (
            <Dropdown menu={{ items }} placement="bottomLeft">
              <MoreOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Dropdown>
          );
        },
        align: 'center',
        width: 50,
        isFix: true,
      },
      {
        title: 'STT',
        key: 'stt',
        render(_: any, record: IDepartment, index: number) {
          return <span>{(params.limit || CONTANTS.PAGE_SIZE) * ((params.page || 1) - 1) + index + 1}</span>;
        },
        ellipsis: true,
        align: 'center',
        width: 50,
        isFix: true,
      },
      ...tableFlex.colsFlex,
    ];
  }, [params.limit, params.page, pathname, tableFlex.colsFlex]);

  const rowSelection: TableRowSelection<IDepartment> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows) => {
      setState((prev) => ({ ...prev, selected: selectedRows }));
    },
  };

  if (!props.info) return null;

  return (
    <Container
      title={props.info.label}
      // breadcrumbs={[{ title: props.info.label }]}
      renderRight={
        <Space>
          {state.selected?.length > 0 && (
            <Tooltip title={lang.t('default.delete')} placement="bottom">
              <Button size="large" type="primary" danger icon={<DeleteOutlined />} onClick={onConfirmDeleteMulti} />
            </Tooltip>
          )}
          <FormSearch
            onSearch={onSearch}
            onRefresh={onClearSearch}
            onFilter={() => setState((prev) => ({ ...prev, filterDrawler: true }))}
            countFilter={state.countFilter}
            setting={props.info}
          />
          <Button.Group>
            <Tooltip title={lang.t('default.export')} placement="bottom">
              <Button size="large" type="primary" onClick={onExport} icon={<DownloadOutlined />} />
            </Tooltip>
            <Tooltip title={lang.t('default.import')} placement="bottom">
              <Button size="large" type="primary" onClick={onImport} icon={<UploadOutlined />} />
            </Tooltip>
          </Button.Group>
          <Link to={`${pathname}/them-moi`}>
            <Button size="large" type="primary" icon={<PlusOutlined />}>
              {lang.t('default.created')}
            </Button>
          </Link>
        </Space>
      }
    >
      <BaseTable
        data={state.data}
        loading={state.loading}
        columns={columns}
        rowSelection={rowSelection}
        onDragField={tableFlex.onDragField}
        onResizeField={tableFlex.onResizeField}
        colsShow={tableFlex.editFields.map((i) => i.field || '')}
      />
      <BasePaginate
        page={params.page}
        take={params.limit || CONTANTS.PAGE_SIZE}
        total={state.total}
        onPageChange={onPageChange}
        onRecordChange={onRecordChange}
        isFixed
      />
      <FilterDrawler
        onClose={() => setState((prev) => ({ ...prev, filterDrawler: false }))}
        open={state.filterDrawler}
        setting={props.info}
        onFilter={onFilter}
      />
    </Container>
  );
}

export default memo(DepartmentScreen);
