import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { ITenant } from 'src/types';
import { pSBC } from 'src/utils';
import { setTenant } from './tenant.slice';

export default function useTenant() {
  const dispatch = useDispatch();
  const tenant = useAppSelector((state) => state.tenant);

  const changeTenant = (tenant: ITenant | null) => {
    dispatch(setTenant(tenant));
  };

  const color = useMemo(() => {
    document
      .querySelector('html')
      ?.style.setProperty('--colorLighter', pSBC(0.96, tenant?.color || '#f0d037') || '#f0d037');
    document
      .querySelector('html')
      ?.style.setProperty('--colorLight', pSBC(0.85, tenant?.color || '#f0d037') || '#f0d037');
    document.querySelector('html')?.style.setProperty('--colorNormal', tenant?.color || '#f0d037');
    document
      .querySelector('html')
      ?.style.setProperty('--colorBold', pSBC(-0.7, tenant?.color || '#f0d037') || '#f0d037');
    document
      .querySelector('html')
      ?.style.setProperty('--colorBolder', pSBC(-0.9, tenant?.color || '#f0d037') || '#f0d037');

    return {
      colorLighter: pSBC(0.96, tenant?.color || '#f0d037') || '#f0d037',
      colorLight: pSBC(0.85, tenant?.color || '#f0d037') || '#f0d037',
      colorNormal: tenant?.color || '#f0d037',
      colorBold: pSBC(1.1, tenant?.color || '#f0d037') || '#f0d037',
      colorBolder: pSBC(1.2, tenant?.color || '#f0d037') || '#f0d037',
    };
  }, [tenant]);

  return {
    tenant,
    changeTenant,
    ...color,
  };
}
