import { Button, Empty, Form, Row } from 'antd';
import { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import lang from 'src/lang/lang';
import { BaseInput, BaseSelect, Container } from 'src/libraries';
import PropertyInput, { IPropertyValue } from 'src/libraries/property-form/PropertyInput';
import { ECollection, ItemService } from 'src/services';
import { useTenant } from 'src/stores';
import {
  IBaseList,
  ICreateDepartmentBody,
  IDepartment,
  IParentChildBody,
  IPropertyCategory,
  IPropertyCategorySetting,
  IPropertyDepartmentBody,
  ISetting,
} from 'src/types';
import { FUNCS, MESSAGE } from 'src/utils';

interface IProps {
  info: ISetting;
}

interface IPropertyCategoryWithValue {
  id?: string;
  propertyCategory: IPropertyCategory;
  values: IPropertyValue[];
  isRequired: boolean;
}

interface IState {
  departmentParentOpts: IBaseList<string>[];
  departmentChildOpts: IBaseList<string>[];
  propertyCategories: IPropertyCategorySetting[];
  propertyCategoriesWithValue: IPropertyCategoryWithValue[];
  loading: boolean;
}

const initState: IState = {
  departmentChildOpts: [],
  departmentParentOpts: [],
  propertyCategories: [],
  propertyCategoriesWithValue: [],
  loading: false,
};

const sv = new ItemService<IDepartment, ICreateDepartmentBody>(ECollection.department);
const svPropertyCategorySetting = new ItemService<IPropertyCategorySetting>(ECollection.property_category_setting);

function CreateDepartmentScreen(props: IProps) {
  const [form] = Form.useForm();
  const [state, setState] = useState<IState>(initState);
  const { tenant } = useTenant();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDepartmentOptions();
    fetchPropertyCategorySetting();
  }, [props.info]);

  const fetchPropertyCategorySetting = async () => {
    // if (state.loading) return;
    // setState((prev) => ({ ...prev, loading: true }));
    try {
      const res = await svPropertyCategorySetting.list({
        fields: ['*', 'property_category_id.properties.*', 'property_category_id.*'],
        filter: {
          setting_id: { _eq: props.info.id },
        },
        sort: ['priority'],
      });
      setState((prev) => ({ ...prev, propertyCategories: res }));
    } catch (error) {
      console.log(error);
    }
    // setState((prev) => ({ ...prev, loading: false }));
  };

  const fetchDepartmentOptions = async () => {
    const settingIds: string[] = [
      ...props.info.children.map((i) => i.child_id),
      ...props.info.parents.map((i) => i.parent_id),
    ];

    try {
      const res = await sv.list({ fields: ['id', 'name', 'setting_id'], filter: { setting_id: { _in: settingIds } } });
      const departmentChildOpts: IBaseList<string>[] = [];
      const departmentParentOpts: IBaseList<string>[] = [];

      settingIds.forEach((i) => {
        const setting = tenant?.settings.find((e) => e.id === i);
        const opts = res
          .filter((e) => (e.setting_id as unknown as string) === i)
          .map((e) => ({ label: e.name, value: e.id }));

        if (setting && opts.length > 0) {
          if (props.info.parents.findIndex((e) => e.parent_id === i) > -1)
            departmentParentOpts.push({
              label: setting.label,
              options: opts,
            });
          if (props.info.children.findIndex((e) => e.child_id === i) > -1)
            departmentChildOpts.push({
              label: setting.label,
              options: opts,
            });
        }
      });

      setState((prev) => ({ ...prev, departmentChildOpts, departmentParentOpts }));
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeProperty = (propertyCategory: IPropertyCategory, values: IPropertyValue[], isRequired: boolean) => {
    setState((prev) => {
      const propertyCategoriesWithValue = prev.propertyCategoriesWithValue;
      if (propertyCategoriesWithValue.findIndex((i) => i.propertyCategory.id === propertyCategory.id) > -1) {
        return {
          ...prev,
          propertyCategoriesWithValue: propertyCategoriesWithValue.map((i) => {
            if (i.propertyCategory.id === propertyCategory.id) {
              return {
                ...i,
                values,
                isRequired,
              };
            }
            return i;
          }),
        };
      }
      return {
        ...prev,
        propertyCategoriesWithValue: [...propertyCategoriesWithValue, { propertyCategory, values, isRequired }],
      };
    });
  };

  const onFinish = async (values: any) => {
    // validate property required
    const errors: string[] = [];
    const propertyBody: IPropertyDepartmentBody[] = [];
    state.propertyCategoriesWithValue.forEach((i) => {
      if (i.isRequired && i.values.length === 0) {
        errors.push(i.propertyCategory.name);
      }

      i.values.forEach((e) => {
        if (e.id) {
          propertyBody.push({ property_id: { id: e.id } });
        } else {
          propertyBody.push({
            property_id: {
              property_category_id: i.propertyCategory.id,
              tenant_id: tenant?.id,
              value: e.value,
              label: e.label,
              image: e.image,
              icon: e.icon,
            },
          });
        }
      });
    });

    if (errors.length > 0) {
      MESSAGE.error(lang.t('department.error_property', { label: errors.join(', ') }));
      return;
    }

    const parentsBody: IParentChildBody[] = [];
    const childrenBody: IParentChildBody[] = [];

    if (values.parent_ids) {
      (values.parent_ids as string[]).forEach((i) => {
        parentsBody.push({ parent_id: i });
      });
    }

    if (values.child_ids) {
      (values.child_ids as string[]).forEach((i) => {
        childrenBody.push({ child_id: i });
      });
    }

    // submit
    if (state.loading) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      // call api
      await sv.create({
        name: values.name,
        code: values.code,
        setting_id: props.info.id,
        tenant_id: tenant?.id || '',
        parents: {
          create: parentsBody,
          update: [],
          delete: [],
        },
        children: {
          create: childrenBody,
          update: [],
          delete: [],
        },
        properties: {
          create: propertyBody,
          update: [],
          delete: [],
        },
        // image?:
      });
      MESSAGE.success(
        lang.t('default.created_success', {
          label: props.info.label.toLocaleLowerCase(),
        }),
      );
      navigate(`/${FUNCS.renderPath(props.info.label)}`);
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  return (
    <Container
      breadcrumbs={[
        {
          title: (
            <Link className="ant-breadcrumb-link" to={`/${FUNCS.renderPath(props.info.label)}`}>
              {props.info.label}
            </Link>
          ),
        },
        { title: lang.t('default.created') },
      ]}
      renderRightBreadcrumb={
        <Button type="primary" loading={state.loading} onClick={() => form.submit()}>
          {lang.t('default.created')}
        </Button>
      }
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <BaseInput name="name" label={lang.t('department.name')} span={24} required />
        </Row>
        <div style={{ marginBottom: 20 }}>
          <Row justify="space-between">
            <p>{lang.t('department.property')}</p>
          </Row>
          <Row gutter={24}>
            {state.propertyCategories.length > 0 ? (
              state.propertyCategories.map((i) => {
                return (
                  <PropertyInput
                    propertyCategory={i.property_category_id}
                    span={i.span}
                    required={i.required}
                    form={form}
                    key={i.id}
                    onChangeProperty={(values) => onChangeProperty(i.property_category_id, values, i.required)}
                  />
                );
              })
            ) : (
              <Empty />
            )}
          </Row>
        </div>
        <Row gutter={24}>
          <BaseSelect
            name="parent_ids"
            label={lang.t('department.parent_ids')}
            span={12}
            options={state.departmentParentOpts}
            mode="multiple"
          />
          <BaseSelect
            name="child_ids"
            label={lang.t('department.child_ids')}
            span={12}
            options={state.departmentChildOpts}
            mode="multiple"
          />
        </Row>
      </Form>
    </Container>
  );
}

export default memo(CreateDepartmentScreen);
