import { ApartmentOutlined, BookOutlined, CoffeeOutlined, SettingOutlined, ShopOutlined } from '@ant-design/icons';
import lang from 'src/lang/lang';

export enum ETenantType {
  SERVICE = 'SERVICE',
  COMPANY = 'COMPANY',
  MERCHANDISE = 'MERCHANDISE',
  SCHOOL = 'SCHOOL',
  FB = 'F&B',
}

interface ITenantType {
  label: string;
  value: ETenantType;
  icon?: any;
}

export const tenantTypes: ITenantType[] = [
  {
    label: lang.t('tenant.sell'),
    icon: <ShopOutlined style={{ fontSize: 20 }} />,
    value: ETenantType.MERCHANDISE,
  },
  {
    label: lang.t('tenant.company'),
    icon: <ApartmentOutlined style={{ fontSize: 20 }} />,
    value: ETenantType.COMPANY,
  },
  {
    label: lang.t('tenant.service'),
    icon: <SettingOutlined style={{ fontSize: 20 }} />,
    value: ETenantType.SERVICE,
  },
  {
    label: lang.t('tenant.food'),
    icon: <CoffeeOutlined style={{ fontSize: 20 }} />,
    value: ETenantType.FB,
  },
  {
    label: lang.t('tenant.school'),
    icon: <BookOutlined style={{ fontSize: 20 }} />,
    value: ETenantType.SCHOOL,
  },
];

// export const serviceCategories: IServiceCategory[] = [
//   { label: 'Kinh doanh', category: EServiceCategory.BUSINESS },
//   { label: 'Tài chính', category: EServiceCategory.FINANCE },
//   { label: 'Quản lý', category: EServiceCategory.MANAGER },
//   { label: 'Lịch', category: EServiceCategory.SCHEDULE },
//   { label: 'Liên hệ', category: EServiceCategory.CONTACT },
// ];

// export const services: IService[] = [
//   { label: 'Sản phẩm', service: EService.product, category: EServiceCategory.BUSINESS },
//   { label: 'Danh mục', service: EService.product_category, category: EServiceCategory.BUSINESS },
//   { label: 'Đơn hàng', service: EService.checkout, category: EServiceCategory.BUSINESS },
//   { label: 'Hoàn hàng', service: EService.turnback, category: EServiceCategory.BUSINESS },
//   { label: 'Nhập hàng', service: EService.session, category: EServiceCategory.BUSINESS },
//   { label: 'Trả hàng', service: EService.return, category: EServiceCategory.BUSINESS },
//   { label: 'Tồn kho', service: EService.shelf, category: EServiceCategory.BUSINESS },
//   { label: 'Khuyến mại', service: EService.sale, category: EServiceCategory.BUSINESS },
//   { label: 'Voucher', service: EService.voucher, category: EServiceCategory.BUSINESS },
//   { label: 'Thanh toán', service: EService.payment, category: EServiceCategory.FINANCE },
//   { label: 'Nợ', service: EService.debt, category: EServiceCategory.FINANCE },
//   { label: 'Ví', service: EService.wallet, category: EServiceCategory.FINANCE },
//   { label: 'Lương thời gian', service: EService.wage_time, category: EServiceCategory.FINANCE },
//   { label: 'Lương lịch', service: EService.wage_schedule, category: EServiceCategory.FINANCE },
//   { label: 'Lương nhiệm vụ', service: EService.wage_task, category: EServiceCategory.FINANCE },
//   { label: 'Lương sản phẩm', service: EService.wage_sell, category: EServiceCategory.FINANCE },
//   { label: 'Lương thành tích', service: EService.wage_achievement, category: EServiceCategory.FINANCE },
//   { label: 'Nhân viên', service: EService.staff, category: EServiceCategory.MANAGER },
//   { label: 'Khách', service: EService.customer, category: EServiceCategory.MANAGER },
//   { label: 'Vai trò', service: EService.role, category: EServiceCategory.MANAGER },
//   { label: 'Chủ đề', service: EService.subject, category: EServiceCategory.MANAGER },
//   { label: 'Thành tích', service: EService.achievement, category: EServiceCategory.MANAGER },
//   { label: 'Lịch', service: EService.schedule, category: EServiceCategory.SCHEDULE },
//   { label: 'Nhiệm vụ', service: EService.task, category: EServiceCategory.SCHEDULE },
//   { label: 'Điểm danh', service: EService.attendance, category: EServiceCategory.SCHEDULE },
//   { label: 'Tin tức', service: EService.news, category: EServiceCategory.CONTACT },
//   { label: 'Tin nhắn', service: EService.message, category: EServiceCategory.CONTACT },
// ];
