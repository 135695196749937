/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */

import { DefaultOptionType } from 'antd/es/select';
import lang from 'src/lang/lang';
import { EService, IAddress, IDataHasTreeMTM, IDataHasTreeOTM, ISetting, IUser } from 'src/types';
import CONTANTS from './constants';

export default class FUNCS {
  static truncateString(input: string, size = CONTANTS.MAX_CHAR_SIZE_TABLE) {
    if (!input) return '';
    return input.length > size ? `${input.substring(0, size)}...` : input;
  }

  static makeid(length = 8) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';

    for (let i = 0; i < length; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static isValidURL(str = '') {
    const res = str.match(
      /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res !== null;
  }

  // static isValidVietnamese(value: string) {
  //   const res = value.match(
  //     /[^a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ!@#$%^&*()_+=;:"'\\|,.<>\/?\[\]]/u,
  //   );
  //   return res === null;
  // }

  // static isValidAZ(value: string) {
  //   const res = value.match(/[^a-z0-9A-Z_.]/u);
  //   return res !== null;
  // }

  static formatCurrencyVND = (value: number) => {
    if (value === undefined || value === null) {
      return lang.t('wallet.not');
    }
    return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  static isValiCharactersFile(value: string) {
    const regex = /[\/#]/;
    const res = regex.test(value);

    return res;
  }

  static onKeyDownNumber(event: any) {
    const k = event.which;
    if ((k < 48 || k > 57) && (k < 96 || k > 105) && k !== 8 && k !== 190) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  static removeDiacritics(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    return str;
  }

  static getFullUrl(path?: string | null) {
    if (!path) return '';
    return process.env.REACT_APP_API_URL + path;
  }

  static getFullMedialUrl(id?: string) {
    const accesstoken = localStorage.getItem('accesstoken');
    // const accesstoken = '3AErleGYaEyctij9rH4YQWhvupBDFCKv';
    if (!id || !accesstoken) return CONTANTS.FALLBACK_IMG;
    return `${process.env.REACT_APP_API_URL}/assets/${id}?access_token=${accesstoken}`;
  }

  static download(data: any, filename: string, type: string) {
    const file = new Blob([data], { type });
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  static getSearchParams(searchParams: URLSearchParams) {
    const result: any = {};
    searchParams.forEach((val, key) => {
      result[key] = val;
    });

    return result;
  }

  static renderPath(label: string) {
    return FUNCS.removeDiacritics(label.toLowerCase()).replaceAll(' ', '-');
  }

  static getOptions(items: any[]): DefaultOptionType[] {
    return items.map((i) => {
      return {
        label: `${i.last_name} ${i.first_name}`,
        value: i.id,
      };
    });
  }

  // Lấy sơ đồ cây quan hệ nhiều nhiều
  static getTreeDataMTM<T extends IDataHasTreeMTM>(
    items: T[],
    parent_id?: string,
    disabledIds?: string[],
  ): DefaultOptionType[] {
    return items
      .filter((i) => {
        return parent_id
          ? (i.parents || []).findIndex((e) => e.parent_id === parent_id) > -1
          : (i.parents || []).length === 0;
      })
      .map((i) => {
        return {
          label: i.name,
          title: i.name,
          value: `${parent_id || ''}.${i.id}`,
          key: `${parent_id || ''}.${i.id}`,
          disabled: disabledIds?.includes(i.id),
          children: FUNCS.getTreeDataMTM(items, i.id, disabledIds),
        };
      });
  }

  // Lấy sơ đồ cây quan hệ một nhiều
  static getTreeDataOTM<T extends IDataHasTreeOTM>(items: T[], parent_id?: string): DefaultOptionType[] {
    return items
      .filter((i) => {
        return parent_id ? i.parent_id === parent_id : !i.parent_id;
      })
      .map((i) => {
        return {
          label: i.name || i.label,
          title: i.name || i.label,
          value: i.id,
          data: i,
          // value: i.id,
          children: FUNCS.getTreeDataOTM(items, i.id),
        };
      });
  }

  // xoá node trong sơ đồ cây quan hệ một nhiều
  static deleteTreeDataOTM<T extends IDataHasTreeOTM>(items: T[], id: string) {
    const deleteIds: string[] = [id];

    function pickIds(idNode: string) {
      items.forEach((i) => {
        if (i.parent_id === idNode) {
          deleteIds.push(i.id);
          pickIds(i.id);
        }
      });
    }

    pickIds(id);

    return items.filter((i) => !deleteIds.includes(i.id));
  }

  static convertToDuration(body: { day?: number; hour?: number; minute?: number; second?: number }) {
    return (body.day || 0) * 24 * 60 * 60 + (body.hour || 0) * 60 * 60 + (body.minute || 0) * 60 + (body.second || 0);
  }

  static convertDurationTime(duration = 0) {
    const seconds = Math.floor(duration % 60);
    const minutes = Math.floor((duration % 3600) / 60);
    const hours = Math.floor((duration % (3600 * 24)) / 3600);
    const days = Math.floor(duration / (3600 * 24));

    const s: string = seconds > 9 ? String(seconds) : `'0'${seconds}`;
    const m: string = minutes > 9 ? String(minutes) : `0${minutes}`;
    const h: string = hours > 9 ? String(hours) : `0${hours}`;
    const d: string = `${days}'d'`;

    const text = `${h}:${m}:${s}`;
    // if (hours > 0) {
    //   text = `${h}:` + text;
    // }

    return { seconds, minutes, hours, days, s, m, h, d, text };
  }

  static convertDuration(dur: number, isRound?: boolean) {
    let hour = 0;
    let minute = 0;
    let second = 0;

    // hour = Math.floor(dur / 3600);
    // minute = Math.floor((dur - hour * 3600) / 60);
    // second = Math.floor(dur - hour * 3600 - minute * 60);
    hour = isRound ? Math.round(dur / 3600) : Math.floor(dur / 3600);
    minute = isRound ? Math.round((dur - hour * 3600) / 60) : Math.floor((dur - hour * 3600) / 60);
    second = isRound ? Math.round(dur - hour * 3600 - minute * 60) : Math.floor(dur - hour * 3600 - minute * 60);
    const render = (time: number) => {
      return time < 10 ? `0${time}` : `${time}`;
    };

    return `${render(hour)}:${render(minute)}:${render(second)}`;
  }

  static createArrayFromNumber(end: number, start: number = 1) {
    const result = [];

    for (let i = start; i <= end; i++) {
      result.push(i);
    }

    return result;
  }

  static getDayOfMonth(month: number) {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;
      case 4:
      case 6:
      case 9:
      case 11:
        return 30;
      case 2:
        return 29;
      default:
        return 31;
    }
  }

  static dayInMonthOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `${lang.t('utils.day')} ${i + 1}`,
  }));

  static convertPrice(n?: number | string, unit: string = 'đ') {
    if (!n) return `0`;

    const lang = localStorage.getItem('tenant_lang');
    if (lang === 'vi-VN') {
      const price = (Math.round(Number(n) * 10) / 10).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      if (unit === '') {
        return price;
      }
      return `${price} ${unit}`;
    }
    const price = Number(n)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    if (unit === '') {
      return price;
    }
    return `${price}$`;
  }

  static convertTime(n?: number | string, unit: string = 's') {
    if (!n) return `0`;
    const price = (Math.round(Number(n) * 10) / 10).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return `${price} ${unit}`;
  }

  static getFullName(user?: IUser) {
    if (!user) return '';
    let result = user.first_name;

    if (user.last_name && result) result += ` ${user.last_name}`;

    return result;
  }

  static getServicePath(service: EService) {
    const settings = JSON.parse(localStorage.getItem('settings') || '') as ISetting[];
    return this.renderPath(settings.find((i) => i.service_id?.name === service)?.label || '');
  }

  static getFullAddress(address?: IAddress) {
    let result = '';

    if (address?.phone) result += address.phone;

    if (address?.detail) result += !result ? address.detail : `, ${address.detail}`;
    if (address?.ward_id?.name_with_type || address?.ward_id?.name)
      result += !result
        ? `${address?.ward_id?.name_with_type || address?.ward_id?.name}`
        : `, ${address?.ward_id?.name_with_type || address?.ward_id?.name}`;
    if (address?.district_id?.name_with_type || address?.district_id?.name)
      result += !result
        ? `${address?.district_id?.name_with_type || address?.district_id?.name}`
        : `, ${address?.district_id?.name_with_type || address?.district_id?.name}`;
    if (address?.province_id?.name_with_type || address?.province_id?.name)
      result += !result
        ? `${address?.province_id?.name_with_type || address?.province_id?.name}`
        : `, ${address?.province_id?.name_with_type || address?.province_id?.name}`;
    if (address?.nation_id?.name_with_type || address?.nation_id?.name)
      result += !result
        ? `${address?.nation_id?.name_with_type || address?.nation_id?.name}`
        : `, ${address?.nation_id?.name_with_type || address?.nation_id?.name}`;

    return result || lang.t('utils.not_yet_have');
  }

  static convertWeight(value: number, unit: string = 'kg') {
    if (unit === 'kg') {
      return `${value / 1000} kg`;
    }

    return `${value} g`;
  }
}
