import { IAddress } from './address.type';
import { IBaseEntity, IBaseQuery, IFee, IRelationBody } from './common.type';
import { IDebt, IDebtBody } from './debt.type';
import { IDeliveryBrand } from './delivery_brand.type';
import { IDeliveryHistory } from './delivery_history.type';
import { IDepartment } from './department.type';
import { IOrder, IOrderBody } from './order.type';
import { IShelf } from './shelf.type';
import { ISocialShop } from './social_shop.type';
import { ITable } from './table.type';
import { ITurnback } from './turnback.type';
import { IUser } from './user.type';
import { IVoucherEntity, IVoucherEntityBody } from './voucher_entity.type';

export interface ICheckout extends IBaseEntity {
  code: string;
  orders: IOrder[];
  status: ICheckoutStatusHistory[];
  debt: IDebt[];
  total: number;
  total_final: number;
  voucher_entities: IVoucherEntity[];
  tenant_id: string;
  department_id: IDepartment;
  user_id: IUser;
  status_current: ECheckoutStatus;
  status_payment_current: ECheckoutStatusPayment;
  address_id: IAddress;
  voucher_price: number;
  fees: IFee[];
  turnbacks: ITurnback[];
  delivery_brand_id?: IDeliveryBrand;
  shelf_id?: IShelf;
  social_shop_id?: ISocialShop;
  delivery_histories?: IDeliveryHistory[];
  table_id: ITable;
}

export interface ICheckoutQuery extends IBaseQuery {
  start?: string;
  end?: string;
  dateRange?: any[];
  product_id?: string;
  department_ids?: string[];
}

export interface ICheckoutBody {
  code?: string;
  orders?: IRelationBody<IOrderBody>;
  status?: ICheckoutStatusHistory[];
  debt?: IRelationBody<IDebtBody>;
  total?: number;
  total_final?: number;
  voucher_entities?: IRelationBody<IVoucherEntityBody>;
  tenant_id?: string;
  department_id?: string;
  user_id?: string;
  status_current?: ECheckoutStatus;
  status_payment_current?: ECheckoutStatusPayment;
  address_id?: string;
  voucher_price?: number;
  fees?: IFee[];
  delivery_brand_id?: string;
  shelf_id?: string;
  table_id?: string;
}

export interface ICheckoutStatusHistory {
  status: ECheckoutStatus;
  date: string;
}

export enum ECheckoutStatus {
  CANCEL,
  CREATED,
  APPROVED,
  SELECTED,
  SHIPPING,
  SOLD,
}

export enum ECheckoutStatusPayment {
  NOT_PAID = 1,
  PARTIAL_PAID = 2,
  PAID = 3,
}
