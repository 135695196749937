import { Col, Pagination, Row, Select, Space } from 'antd';
import { CONTANTS } from 'src/utils';

interface IProps {
  page?: number;
  take: number;
  total: number;
  onPageChange: (page: number) => void;
  onRecordChange?: (record: number) => void;
  pageSizeOptions?: number[];
  isFixed?: boolean;
}

const { Option } = Select;
export default function BasePaginate(props: IProps) {
  const {
    page = 1,
    take = CONTANTS.PAGE_SIZE,
    total,
    onPageChange,
    onRecordChange,
    pageSizeOptions = [10, 15, 20, 25],
  } = props;

  const handleRecordChange = (value: number) => {
    if (onRecordChange) {
      onRecordChange(value);
    }
  };

  return (
    <Row
      gutter={24}
      align="middle"
      justify="space-between"
      style={props.isFixed ? { position: 'absolute', bottom: 0, left: 0, right: 0 } : {}}
    >
      <Col
        style={{
          display: 'inline-flex',
          justifyContent: 'flex-end',
        }}
      >
        <div className="d-flex justify-content-end pagination">
          <Pagination
            size="small"
            showSizeChanger={false}
            total={total}
            showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}
            current={page}
            pageSize={take}
            defaultPageSize={take}
            onChange={onPageChange}
          />
        </div>
      </Col>
      <Col>
        <Space>
          {/* <span>{`Hiển thị ${total > take ? take : total} trên tổng số ${total} bản ghi`}</span> */}
          <Select onChange={handleRecordChange} defaultValue={take}>
            {pageSizeOptions.map((e) => {
              return (
                <Option key={e} value={e}>
                  {e}
                </Option>
              );
            })}
          </Select>
        </Space>
      </Col>
    </Row>
  );
}
