/* eslint-disable new-cap */
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import lang from 'src/lang/lang';
import { ECollection, ItemService } from 'src/services';
import { ICheckout, ITenant } from 'src/types';
import '../assets/fonts/BeVietnam-Bold-normal';
import '../assets/fonts/BeVietnam-Regular-normal';
import CONTANTS from './constants';
import FUNCS from './func';

const sv = new ItemService<ICheckout>(ECollection.checkout);

export default class PDF {
  static async printCheckoutBill(checkout_id: string, tenant: ITenant | null) {
    try {
      if (!tenant) return;

      const checkout = await sv.detail(checkout_id, {
        fields: [
          'id',
          'code',
          'total',
          'total_final',
          'user_id.first_name',
          'user_id.last_name',
          'orders.id',
          'orders.product_id.name',
          'orders.quantity',
          'orders.price',
          'fees',
          'date_created',
          'table_id.name',
        ],
      });
      if (!checkout) return;

      const doc = new jsPDF({
        orientation: 'p', // 'p' cho chiều dọc
        unit: 'mm', // Đơn vị là mm
        format: [58, 88 + checkout.orders.length * 4], // Kích thước [width, height] = [80mm, 60mm]
      });

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHalfWidth = doc.internal.pageSize.getWidth() / 2;

      const lineHeight = 4;
      let y = 10;

      doc.setFont('BeVietnam-Bold');
      // Tiêu đề hóa đơn
      doc.setFontSize(8);

      doc.text(tenant.name, pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      doc.text(`${lang.t('utils.phone')}: ${tenant.phone}`, pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      doc.text(`--------------`, pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      doc.text(lang.t('utils.sale_invoice'), pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      doc.text(`${lang.t('utils.sale_invoice')}: ${checkout.code}`, pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      doc.text(`${lang.t('utils.day')}: ${dayjs(checkout.date_created).format(CONTANTS.DATE_TIME)}`, pageHalfWidth, y, {
        align: 'center',
      });
      y += lineHeight;

      doc.setFont('BeVietnam-Regular');
      doc.setFontSize(7);
      y += lineHeight / 2;

      doc.text(
        `${lang.t('utils.customer')}: ${checkout.user_id ? FUNCS.getFullName(checkout.user_id) : 'Khách lẻ'}`,
        4,
        y,
      );
      y += lineHeight;

      if (checkout.table_id) {
        doc.text(`${lang.t('utils.table')}: ${checkout.table_id.name}`, 4, y);
        y += lineHeight;
      }

      doc.setFont('BeVietnam-Bold');
      doc.setFontSize(7);
      y += lineHeight / 2;

      // Header
      const productNameWidth = (pageWidth - 8) * (50 / 100);
      const priceWidth = (pageWidth - 8) * (20 / 100);
      const quantityWidth = (pageWidth - 8) * (10 / 100);
      const totalWidth = (pageWidth - 8) * (20 / 100);

      doc.text(lang.t('utils.product_name'), 4, y, { maxWidth: productNameWidth });
      doc.text(lang.t('utils.price'), 4 + productNameWidth + priceWidth, y, { maxWidth: priceWidth, align: 'right' });
      doc.text(lang.t('utils.quantity_sort'), 4 + productNameWidth + priceWidth + quantityWidth, y, {
        maxWidth: quantityWidth,
        align: 'right',
      });
      doc.text(lang.t('utils.total_sort'), 4 + productNameWidth + priceWidth + quantityWidth + totalWidth, y, {
        maxWidth: totalWidth,
        align: 'right',
      });
      doc.line(4, y + 1, pageWidth - 4, y + 1);
      y += lineHeight;

      doc.setFont('BeVietnam-Regular');
      doc.setFontSize(6);
      checkout.orders.forEach((i) => {
        doc.text(i.product_id.name, 4, y, { maxWidth: productNameWidth });
        doc.text(FUNCS.convertPrice(i.price, ''), 4 + productNameWidth + priceWidth, y, {
          maxWidth: priceWidth,
          align: 'right',
        });
        doc.text(i.quantity.toString(), 4 + productNameWidth + priceWidth + quantityWidth, y, {
          maxWidth: quantityWidth,
          align: 'right',
        });
        doc.text(
          FUNCS.convertPrice(i.price * i.quantity, ''),
          4 + productNameWidth + priceWidth + quantityWidth + totalWidth,
          y,
          {
            maxWidth: totalWidth,
            align: 'right',
          },
        );

        doc.line(4, y + 1, pageWidth - 4, y + 1);
        y += lineHeight;
      });

      doc.setFont('BeVietnam-Bold');
      doc.setFontSize(7);
      y += lineHeight / 2;

      doc.text(lang.t('utils.total_cost'), 4, y);
      doc.text(
        FUNCS.convertPrice(
          checkout.orders.reduce((sum, i) => sum + i.quantity * i.price, 0),
          '',
        ),
        pageWidth - 4,
        y,
        { align: 'right' },
      );
      y += lineHeight;

      doc.text(lang.t('utils.expense'), 4, y);
      doc.text(
        FUNCS.convertPrice(
          checkout.fees.reduce((sum, i) => sum + i.cost, 0),
          '',
        ),
        pageWidth - 4,
        y,
        { align: 'right' },
      );
      y += lineHeight;

      doc.text(lang.t('utils.total_sum'), 4, y);
      doc.text(FUNCS.convertPrice(checkout.total_final, ''), pageWidth - 4, y, { align: 'right' });
      y += lineHeight;

      y += lineHeight / 2;
      doc.text('---------------', pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      doc.text(lang.t('utils.goodbye'), pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      doc.text(lang.t('utils.powered'), pageHalfWidth, y, { align: 'center' });
      y += lineHeight;

      // Lưu file
      const pdfBlob = doc.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);

      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.log(error);
    }
  }
}
