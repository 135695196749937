import dayjs from 'dayjs';
import { IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IFile } from './file.type';
import { IScheduleDepartment, IScheduleDepartmentBody } from './schedule-department.type';
import { IScheduleDocumentBody } from './schedule-document.type';
import { IScheduleFileBody } from './schedule-file.type';
import { IScheduleUser, IScheduleUserBody } from './schedule-user.type';
import { IShelf } from './shelf.type';
import { IShift } from './shift.type';
import { ITaskSchedule, ITaskScheduleBody } from './task-schedule.type';

// @ts-ignore
export interface ISchedule extends IBaseEntity {
  name: string;
  start: string;
  end: string;
  departments: IScheduleDepartment[];
  parent_id: ISchedule;
  mode: EScheduleMode;
  shelf_id: IShelf;
  note: string;
  tenant_id: string;
  // documents: [];
  users: IScheduleUser[];
  children: ISchedule[];
  // wage_schedules: [];
  tasks: ITaskSchedule[];
  files: IFile[];
  config: IScheduleConfig[];
  shift_id: IShift;
  itinerary: IItinerary[];
  count: number;
  type: EScheduleType;
}

export interface IScheduleQuery extends IBaseQuery {
  name?: string;
  mode?: EScheduleMode;
  department_ids?: string[];
  shelf_ids?: string[];
  parent_ids?: string[];
  start?: string;
  end?: string;
}
export interface IScheduleBody {
  name?: string;
  start?: string;
  end?: string;
  departments?: IRelationBody<IScheduleDepartmentBody>;
  // parent_id: ISchedule;
  mode?: EScheduleMode;
  shelf_id?: string;
  note?: string;
  documents?: IRelationBody<IScheduleDocumentBody>;
  users?: IRelationBody<IScheduleUserBody>;
  children?: IRelationBody<IScheduleBody>;
  // wage_schedules: [];
  // tasks: [];
  files?: IRelationBody<IScheduleFileBody>;
  config?: IScheduleConfig[];
  shift_id?: string;
  itinerary?: IItinerary[];
  count?: number;
  type?: EScheduleType;
  tasks?: IRelationBody<ITaskScheduleBody>;
}

export interface ICreateScheduleBody extends IScheduleBody {}
export interface IUpdateScheduleBody extends IScheduleBody {}

export interface IScheduleConfigProvider {
  mode: EScheduleConfigMode;
  duration: number;
  start: dayjs.Dayjs;
  repeat_type?: EScheduleConfigRepeatType;
  repeat_end?: dayjs.Dayjs;
  repeat_count?: number;
  repeat_duration: number;
  repeat_config_time: IScheduleConfigRepeatTime[];
  schedule_default: IShift[];
  end_type?: EScheduleConfigEndType;
}

export interface IScheduleConfig {
  cycle: number;
  shift_id?: string;
  start: number;
  duration: number;
  day?: number;
  dayOfWeek?: number;
  dayOfMonth?: number;
  weekOfMonth?: number;
  weekOfYear?: number;
  month?: number;
  year?: number;
  itinerary?: IItineraryConfig[];
}

export enum EScheduleType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  MONTH_BY_WEEK = 'MONTH_BY_WEEK',
  YEAR_BY_WEEK = 'YEAR_BY_WEEK',
  YEAR_BY_MONTH_BY_WEEK = 'YEAR_BY_MONTH_BY_WEEK',
  CUSTOM = 'CUSTOM',
  INSTANT = 'INSTANT',
  ONCE = 'ONCE',
}

export enum EScheduleMode {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum EScheduleConfigMode {
  INSTANT = 'INSTANT',
  ONCE = 'ONCE',
  REPEAT = 'REPEAT',
}

export enum EScheduleConfigEndType {
  DATE = 'DATE',
  COUNT = 'COUNT',
}

export enum EScheduleAchievementType {
  ATTENDED = 'Có mặt',
  ONTIME = 'Đúng, đủ giờ',
}

export enum EProductAchievementType {
  QUANTITY = 'Số lượng',
  VALUE = 'Giá trị',
}

export enum EScheduleConfigRepeatType {
  BY_DAY = 'DAY',
  BY_WEEK = 'WEEK',
  BY_MONTH = 'MONTH',
  BY_YEAR = 'YEAR',
  BY_MONTH_WEEK = 'MONTH_BY_WEEK',
  BY_YEAR_WEEK = 'YEAR_BY_WEEK',
  BY_YEAR_MONTH_WEEK = 'YEAR_BY_MONTH_BY_WEEK',
  CUSTOM = 'CUSTOM',
}

export interface IScheduleConfigRepeatTime {
  tempId?: string;
  start: dayjs.Dayjs;
  duration: number;
  repeatDuration?: number;
  end: dayjs.Dayjs;
  day_week?: number;
  day_month?: number;
  day_year?: string; // month-day
  week_year?: number;
  week_month?: number;
  week_month_year?: string; // month-week
  type?: EScheduleConfigRepeatType;
  makeTimeId?: string;
  shiftId?: string;
  itinerary?: IItineraryConfig[];
}

export interface IItinerary {
  id: string;
  name: string;
  start: string;
  end: string;
  parent_id?: string;
  children?: string[];
}
export interface IItineraryConfig {
  id: string;
  name: string;
  start: number;
  duration: number;
  parent_id?: string;
  children?: string[];
}
