import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { SpinnerLoader } from 'src/libraries';
import { ECollection, ItemService, UserService } from 'src/services';
import { useDepartment, useRefresh, useTenant, useUser } from 'src/stores';
import { EDepartmentType, ERoleLevel, IBaseProps, IDepartment, ITenant } from 'src/types';

const svUser = new UserService();
const svTenant = new ItemService<ITenant>(ECollection.tenant);

function InitAppProvider({ children }: IBaseProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { changeUser } = useUser();
  const { changeTenant } = useTenant();
  const { changeDepartment } = useDepartment();
  const { refresh } = useRefresh();

  useEffect(() => {
    fetchInit();
  }, [refresh]);

  const fetchInit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const user = await svUser.me({
        fields: [
          // '*',
          'id',
          'tenant_id',
          'avatar',
          'email',
          'first_name',
          'last_name',
          'role.id',
          'role.name',
          'role.level',
          'role.policies.policy.name',
          'departments.id',
          'departments.department_id.id',
          'departments.department_id.name',
          'departments.department_id.parents.id',
          'departments.department_id.parents.parent_id',
        ],
      });

      if (user) {
        // Lưu thông tin user vào redux
        changeUser(user);
        // Lấy thông tin tenant
        if (user.tenant_id) {
          const tenant = await svTenant.detail(user.tenant_id, {
            fields: [
              'id',
              'name',
              'layout',
              'theme',
              'color',
              'image',
              'product_stock',
              'product_serial',
              'department',
              'social',
              'shipping',
              'offline',
              'language',
              'phone',
              'email',
              'department_type',
              'tenant_category_id',
              'sepay_token',
              'bank_code',
              'bank_account',
              'address.id',
              'address.phone',
              'address.detail',
              'address.nation_id',
              'address.province_id',
              'address.district_id',
              'address.ward_id',
              'settings.id',
              'settings.label',
              'settings.icon',
              'settings.menu',
              'settings.fields',
              'settings.is_group_menu',
              // 'settings.parents.*',
              // 'settings.children.*',
              'settings.services.id',
              'settings.services.service_id.name',
              'settings.services.service_id.id',
              'settings.service_id.name',
              'settings.service_id.id',
              'departments.id',
              'departments.name',
              'departments.parents.id',
              'departments.parents.parent_id',
              'departments.parents.child_id',
              'social_shops.id',
              'social_shops.name',
            ],
            deep: {
              settings: {
                services: {
                  _sort: 'priority',
                },
                _sort: 'menu',
                _filter: {
                  _or: [
                    {
                      menu_roles: {
                        directus_roles_id: {
                          _eq: user.role?.id,
                        },
                      },
                    },
                    {
                      is_group_menu: true,
                    },
                  ],
                },
              },
            },
          });
          changeTenant(tenant);

          changeDepartment({
            departments:
              user.role.level === ERoleLevel.TENANT
                ? tenant.departments
                : user.departments.map((i) => i.department_id as IDepartment),
            myDepartments: user.departments.map((i) => i.department_id as IDepartment),
            allDepartments: tenant.departments,
            label:
              tenant.department_type === EDepartmentType.BRANCH
                ? lang.t('library.branch')
                : lang.t('voucher.department'),
          });

          localStorage.setItem('settings', JSON.stringify(tenant.settings));
          localStorage.setItem('tenant_lang', tenant.language);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  if (loading) return <SpinnerLoader bg={false} />;

  return children;
}

export default memo(InitAppProvider);
