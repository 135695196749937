/* eslint-disable no-case-declarations */
import { AxiosError } from 'axios';
import { memo, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Subscription } from 'rxjs';
import lang from 'src/lang/lang';
import { Routes } from 'src/routes';
import { useUser } from 'src/stores';
import { EErorCode, HttpStatusCode, IBaseProps, IBaseResponse } from 'src/types';
import { MESSAGE } from 'src/utils';
import { EventBusName, IBaseEventPayload } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';

interface IProps extends IBaseProps {}

function ErrorProvider(props: IProps) {
  const subscriptions = useRef<any>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { changeUser } = useUser();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: IBaseEventPayload<AxiosError<any>>) => {
        switch (data.type) {
          case EventBusName.ERROR:
            const { response } = data.payload;
            const { data: res } = response as { status: HttpStatusCode; data: IBaseResponse<any> };

            if (res.errors) {
              const error = res.errors[0];
              if (error?.extensions?.code) {
                switch (error.extensions.code) {
                  case EErorCode.ECONNABORTED:
                    navigate(Routes.PAGE500.path);
                    break;
                  case EErorCode.FORBIDDEN:
                    MESSAGE.error(lang.t('library.no_access_function'));
                    break;
                  case EErorCode.INVALID_CREDENTIALS:
                    MESSAGE.error(lang.t('library.login_info_incorrect'));

                    if (pathname !== Routes.LOGIN.path) {
                      navigate(Routes.LOGIN.path);
                    }

                    break;
                  case EErorCode.TOKEN_EXPIRED:
                    MESSAGE.error(lang.t('library.login_session_expired'));
                    navigate(Routes.LOGIN.path);
                    localStorage.removeItem('accesstoken');
                    localStorage.removeItem('refreshtoken');
                    // xoá redux
                    changeUser(null);
                    break;

                  default:
                    MESSAGE.error(error?.message || lang.t('library.system_error'));
                    break;
                }
              } else {
                MESSAGE.error(error?.message || lang.t('library.system_error'));
              }
            }

            break;
          default:
            break;
        }
      }),
    );
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  return props.children;
}

export default memo(ErrorProvider);
