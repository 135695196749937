import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { BaseCheckbox, BaseInput, BaseRadio, BaseSelect } from 'src/libraries';
import { AuthService, ECollection, ItemService } from 'src/services';
import FlowService from 'src/services/flow.service';
import { useLang, useRefresh, useTenant, useUser } from 'src/stores';
import { EDepartmentType, IBaseList, IBaseProps, IDistrict, IProvince, ITenantCategory } from 'src/types';
import { CONTANTS, MESSAGE } from 'src/utils';
import { tenantTypes } from './contant';
import styles from './tenant.module.scss';

interface IProps extends IBaseProps {}

interface IState {
  loading: boolean;
  step: number;
  tenantCategory?: ITenantCategory;
  tenantCategories: ITenantCategory[];
  loadingTenantCategory: boolean;
  sellType: (1 | 2)[];

  loadingDistrict: boolean;
  provinceOpts: IBaseList<string>[];
  districtOpts: IBaseList<string>[];
}

const initState: IState = {
  loading: false,
  step: 1,
  tenantCategories: [],
  loadingTenantCategory: false,
  sellType: [],

  loadingDistrict: false,
  provinceOpts: [],
  districtOpts: [],
};

const svFlow = new FlowService();
const svAuth = new AuthService();
const svTenantCategory = new ItemService<ITenantCategory>(ECollection.tenant_category);
const svProvince = new ItemService<IProvince>(ECollection.province);
const svDistrict = new ItemService<IDistrict>(ECollection.district);

function RegisterTenantScreen(props: IProps) {
  const [form] = Form.useForm();
  const { user } = useUser();
  const { tenant } = useTenant();
  const { changeRefresh, refresh } = useRefresh();
  const [state, setState] = useState<IState>(initState);
  const { locale } = useLang();

  useEffect(() => {
    fetchTenantCategory();
    fetchProvince();
  }, []);

  const fetchTenantCategory = async () => {
    if (state.loadingTenantCategory) return;
    setState((prev) => ({ ...prev, loadingTenantCategory: true }));
    try {
      const res = await svTenantCategory.list({
        fields: ['id', 'name', 'translations.*', 'type', 'department'],
        filter: {
          active: { _eq: true },
        },
        deep: {
          translations: {
            _filter: {
              language_code: { _eq: localStorage.getItem('lang') === 'vi' ? 'vi-VN' : 'en-US' },
            },
          },
        },
      });

      setState((prev) => ({ ...prev, tenantCategories: res }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loadingTenantCategory: false }));
  };

  const fetchProvince = async () => {
    try {
      const res = await svProvince.list({
        fields: ['*'],
        sort: ['code'],
        filter: {
          nation_id: { _eq: '99ca8ccb-85d6-491b-8fd2-98c9aee05a9e' },
        },
      });

      setState((prev) => ({ ...prev, provinceOpts: res.map((i) => ({ label: i.name_with_type, value: i.id })) }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistrict = async (province_id: string) => {
    if (state.loadingDistrict) return;
    setState((prev) => ({ ...prev, loadingDistrict: true }));
    try {
      const res = await svDistrict.list({
        fields: ['*'],
        filter: {
          province_id: { _eq: province_id },
        },
        sort: ['code'],
      });

      setState((prev) => ({ ...prev, districtOpts: res.map((i) => ({ label: i.name_with_type, value: i.id })) }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loadingDistrict: false }));
  };

  const onChangeProvince = (val: string) => {
    form.setFieldsValue({
      district_id: null,
      ward_id: null,
    });
    if (!val) return;
    fetchDistrict(val);
  };

  const onChooseCategory = (item: ITenantCategory) => {
    setState((prev) => ({ ...prev, tenantCategory: item }));
  };

  const onFinish = async (values: any) => {
    if (state.loading || !state.tenantCategory) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await svFlow.createTenant({
        name: values.name,
        phone: values.phone,
        email: values.email,
        department_type: values.department_type || EDepartmentType.DEPARTMENT,
        lang: localStorage.getItem('lang') === 'vi' ? 'vi-VN' : 'en-US',
        tenant_category_id: state.tenantCategory.id,
        province_id: values.province_id,
        district_id: values.district_id,
        detail: values.detail,
        shipping: state.sellType.includes(2),
        offline: state.sellType.includes(1),
        nation_id: '99ca8ccb-85d6-491b-8fd2-98c9aee05a9e',
      });

      MESSAGE.success('Hoàn tất cài đặt cơ sở thành công!');

      // refresh token
      const refreshtoken = localStorage.getItem('refreshtoken');
      if (refreshtoken) {
        const res = await svAuth.refresh({ refresh_token: refreshtoken, mode: 'json' });
        localStorage.setItem('accesstoken', res.access_token);
        localStorage.setItem('refreshtoken', res.refresh_token);
        localStorage.setItem('expire', dayjs().add(res.expires, 'milliseconds').format(CONTANTS.DATE_TIME_SERVER));
      }

      changeRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  if (!user) return null;

  if (!tenant) {
    return (
      <div className={styles.registerTenantWrapper}>
        <Row align="middle">
          <Col span={11}>
            <div className={state.step === 1 ? '' : 'hidden'}>
              <Typography.Title level={2}>{lang.t('tenant.business_type')}</Typography.Title>
              <Spin spinning={state.loadingTenantCategory}>
                <div style={{ maxHeight: 400, overflowY: 'scroll', overflowX: 'hidden' }}>
                  {tenantTypes.map((i, index: number) => {
                    const tenantCategories = state.tenantCategories.filter((e) => e.type === i.value);

                    return (
                      <div key={index} style={{ marginBottom: 10 }}>
                        <Space style={{ marginBottom: 4 }}>
                          {i.icon}
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            {i.label}
                          </Typography.Title>
                        </Space>
                        <Row gutter={8}>
                          {tenantCategories.map((e) => {
                            const active = e.id === state.tenantCategory?.id;
                            return (
                              <Col span={6} key={e.id}>
                                <div
                                  className={active ? `${styles.serviceItem} ${styles.active}` : styles.serviceItem}
                                  onClick={() => onChooseCategory(e)}
                                >
                                  {e.translations[0].label}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    );
                  })}
                </div>
              </Spin>

              <Row justify="end" style={{ marginTop: 24 }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => setState((prev) => ({ ...prev, step: 2 }))}
                    icon={<ArrowRightOutlined />}
                    disabled={!state.tenantCategory}
                  >
                    {lang.t('tenant.nexr')}
                  </Button>
                </Space>
              </Row>
            </div>

            <Form form={form} className={state.step === 2 ? '' : 'hidden'} onFinish={onFinish}>
              <Typography.Title>{lang.t('tenant.business_information')}</Typography.Title>
              <Row gutter={12}>
                <BaseInput placeholder={lang.t('tenant.name')} name="name" span={24} required />
                <BaseInput placeholder={lang.t('tenant.phone')} name="phone" span={12} required isPhone />
                <BaseInput placeholder="Email" name="email" span={12} required isEmail />
                {state.tenantCategory?.department && (
                  <BaseRadio
                    label={lang.t('tenant.scale')}
                    name="department_type"
                    span={24}
                    options={[
                      { label: lang.t('tenant.independence'), value: EDepartmentType.DEPARTMENT },
                      { label: lang.t('tenant.branch_chain'), value: EDepartmentType.BRANCH },
                    ]}
                    noMessage
                  />
                )}
                <BaseCheckbox
                  label={lang.t('tenant.business_form')}
                  span={24}
                  options={[
                    { label: lang.t('tenant.counter'), value: 1 },
                    { label: lang.t('tenant.online'), value: 2, disabled: locale !== 'vi' },
                  ]}
                  onChange={(checked) => setState((prev) => ({ ...prev, sellType: (checked as (1 | 2)[]) || [] }))}
                />
                {state.sellType.includes(2) && (
                  <>
                    <BaseSelect
                      name="province_id"
                      placeholder={lang.t('default.province_id')}
                      span={12}
                      onChange={(val) => onChangeProvince(val as string)}
                      options={state.provinceOpts}
                      showSearch
                      required
                    />
                    <BaseSelect
                      name="district_id"
                      placeholder={lang.t('default.district_id')}
                      span={12}
                      options={state.districtOpts}
                      required
                      loading={state.loadingDistrict}
                      showSearch
                    />

                    <BaseInput placeholder={lang.t('tenant.detail_address')} name="detail" span={24} required />
                  </>
                )}
              </Row>
              <Row justify="end">
                <Space>
                  <Button icon={<ArrowLeftOutlined />} onClick={() => setState((prev) => ({ ...prev, step: 1 }))}>
                    {lang.t('tenant.complete')}
                  </Button>
                  <Button type="primary" htmlType="submit" icon={<CheckOutlined />} loading={state.loading}>
                    {lang.t('tenant.back')}
                  </Button>
                </Space>
              </Row>
            </Form>

            {/* <div className={state.step === 2 ? '' : 'hidden'}>
              <Typography.Title>Chọn lĩnh vực kinh doanh của bạn</Typography.Title>

              <Row gutter={12}>
                {tenantTypes.map((i, index) => (
                  <Col span={6} key={index}>
                    <div
                      className={
                        state.tenantType === i.value
                          ? `${styles.tenantTypeItem} ${styles.active}`
                          : styles.tenantTypeItem
                      }
                      onClick={() => onChaneTenantType(i.value)}
                    >
                      <Space>
                        {i.icon}
                        <span>{i.label}</span>
                      </Space>
                    </div>
                  </Col>
                ))}
              </Row>

              <Row justify="end" style={{ marginTop: 24 }}>
                <Space>
                  <Button icon={<ArrowLeftOutlined />} onClick={() => setState((prev) => ({ ...prev, step: 1 }))}>
                    Trở về
                  </Button>
                  <Button
                    type="primary"
                    icon={<ArrowRightOutlined />}
                    onClick={() => setState((prev) => ({ ...prev, step: 3 }))}
                  >
                    Tiếp theo
                  </Button>
                </Space>
              </Row>
            </div> */}
          </Col>
          <Col span={13} style={{ textAlign: 'center' }}>
            <img src="/image/logo.png" alt="logo" style={{ width: '60%' }} />
          </Col>
        </Row>
      </div>
    );
  }

  return props.children;
}

export default memo(RegisterTenantScreen);
