import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { IDepartment } from 'src/types';

export interface IDepartmentSlice {
  myDepartments: IDepartment[];
  departments: IDepartment[];
  allDepartments: IDepartment[];
  label: string;
}

const initialState: IDepartmentSlice = {
  myDepartments: [],
  departments: [],
  allDepartments: [],
  label: 'Phòng ban',
};

const departmentSlice = createSlice<IDepartmentSlice, SliceCaseReducers<IDepartmentSlice>>({
  name: 'department',
  initialState,
  reducers: {
    setDepartment: (state, { payload }: PayloadAction<IDepartmentSlice>) => {
      // if (state) {
      //   return {
      //     ...state,
      //     ...payload,
      //   };
      // }
      return payload;
    },
  },
});

export const { setDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
